import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=5bb4203b&scoped=true"
import script from "./ChangePassword.vue?vue&type=script&lang=js"
export * from "./ChangePassword.vue?vue&type=script&lang=js"
import style0 from "./ChangePassword.vue?vue&type=style&index=0&id=5bb4203b&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb4203b",
  null
  
)

export default component.exports