<template>
  <div class="login">
    <div class="logo">
      <img v-if="logUri" :src="logUri" alt="" />
    </div>

    <div class="region">
      <div v-if="inputEnabled">
        <div class="each">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconuser"></use>
          </svg>
          <input
            v-model="userName"
            class="input"
            type="text"
            placeholder="请输入员工号"
          />
        </div>
        <div class="each">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconpassword"></use>
          </svg>
          <input
            v-model="password"
            class="input"
            type="password"
            placeholder="请输入密码"
          />
        </div>
        <p class="float-right">
          <span @click="forgetPsw">忘记密码？</span>
        </p>
        <van-button class="btnList-login" size="large" @click="loginBtn"
          >{{pwdBtnName}}</van-button
        >
      </div>
      <van-button
        class="btnList-login"
        size="large"
        v-if="ssoEnabled"
        @click="ssoLogin"
        >{{ssoBtnName}}</van-button
      >
    </div>

    <div class="vesion-info" v-if="logUri && version">
      <p class="Powered">Powered By 会引擎</p>
      <p class="vesion">
        {{ version || "" }}
      </p>
    </div>
  </div>
</template>

<script>
import login from "../controllers/login.js";
export default {
  extends: login,
};
</script>

<style lang="less">
.login {
  .logo {
    margin: 0.6rem 0;
    text-align: center;
    img {
      width: 1.08rem;
      height: 1.08rem;
      border-radius: 8px;
    }
  }
  .region {
    margin: 0.4rem;
    padding: 0.02rem;
    vertical-align: middle;
    .each {
      margin: 0.16rem 0;
      border: 1px solid #ededed;
      border-radius: 0.04rem;
      .icon {
        width: 0.22rem;
        height: 0.22rem;
        fill: currentColor;
        overflow: hidden;
        vertical-align: middle;
        margin: 0 0 0.06rem 0.06rem;
      }
      .input {
        width: 2.4rem;
        height: 0.4rem;
        border: none;
        outline: none;
        padding: 0 0 0 0.1rem;
      }
    }
    .float-right {
      margin: 0;
      text-align: right;
      // color: var(--themeColor);
      color: #999;
    }
    .btnList-login {
      height: 0.4rem;
      line-height: 0.4rem;
      margin: 0.2rem 0 0;
      border-radius: 0.04rem;
      color: white;
      background: var(--themeColor);
    }
  }
  .vesion-info {
    width: 100vw;
    position: fixed;
    bottom: .2rem;
    margin-top: 1rem;
    text-align: center;
    color: #999;
    font-size: .12rem;
  }
}
</style>
