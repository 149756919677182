import {
    httpService
} from '../service/httpService'
import {
    getCookie,removeCookie
} from 'tiny-cookie';
import PublicMethod from '../plugins/public'
import { SmartStorage } from 'smart-core-util'
import {
    Toast
} from 'vant';
let apiUrs = {
    query: '/api/engine/event/Disclaimer/GetMyLastUnread',
    read: '/api/engine/event/Disclaimer/Read',
    getme: '/foundation/passport/IUserService/GetMe',
}
export default {
    async beforeRouteEnter(to, from, next) {
        // 这里判断是否需要当前 步骤
        if (getCookie("tenant") == "roche" || getCookie("tenant_code") == "roche") {
            apiUrs.query = '/api/tenants/roche/Disclaimer/GetMyLastUnread'; // 罗氏
            apiUrs.read = '/api/tenants/roche/Disclaimer/Read'; // 罗氏
        }
        let result = await httpService.getMyLastUnread(apiUrs.query);
        if (result.success && !result.content) {
            PublicMethod.nextRouter()
        } else {
            // 往路由地址中添加参数
            to.query.content = result.content;
            PublicMethod.closeLoading();
            next();
        }
    },
    data() {
        return {
            accepted: false,
            model: {
                content: '',
                disclaimerId: ''
            }
        }
    },
    mounted() {
        this.model = this.$route.query.content || {}
    },
    methods: {
        // 接受 免责声明
        accept() {
            this.accepted = document.getElementById("selectCheck").checked;
        },
        // 确认 免责声明
        async readAsync(opinion) {
            if (!opinion) {
                Toast("感谢您的反馈!")
                this.onClickLeft();
                return;
            }

            // 删除不需要的content
            delete this.model.content;

            var response = await httpService.read(apiUrs.read, this.model);
            if (response) {
                // 确认 免责声明后的 步骤
                PublicMethod.nextRouter()
                // this.goAfter();
            }
        },
        // 确认 免责声明后的 步骤
        goAfter() {
            if (getCookie("tenant") == "ipsen") {
                this.$router.push('/welcome');
            } else {
                this.$router.push('/alipay');
            }

            this.publicMethod.openLoading();
        },
        // 返回
        onClickLeft() {
            removeCookie('token');
            removeCookie("token",{ domain: window.idomain }); 
            SmartStorage.set("currentRouter", 'login');
            this.$router.push('/login');
        }
    }
}