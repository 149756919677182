// import Vue from 'vue'
import {
    getAllCookies,
    removeCookie
} from 'tiny-cookie'
import {
    Toast
} from 'vant';
// import jwtDecode from 'jwt-decode';
import {
    SmartStorage
} from 'smart-core-util'
import router from '../router/index.js';

// import axios from 'axios';

// const VUE_APP_GATEWAY = process.env.VUE_APP_GATEWAY;
// 加载动画
// import loading from '../components/loading/loading.js'

let PublicMethod = {
    // 从地址栏获取信息(tenant,platform等)，并存入cookie
    // getParams(){
    //     let url = window.location.search.substring(1);
    //     if(url){
    //         let test = url.split('&');
    //         test.forEach(item => {
    //             let temp = item.split('=')
    //             setCookie(temp[0],temp[1],{expires: '31104000s',domain:window.idomain})
    //         })
    //     }
    // },
    // 获取Uris
    // async getHttpUris(params){
    //     return await axios.post(VUE_APP_GATEWAY + "/api/tpm/cfg/getsettings",params);
    // },
    // token解析
    // analysisToken(token){
    //     return jwtDecode(token);
    // },
    // 获取各个ip地址
    // getUrls(){
    //     let Uris = getCookie('Uris')
    //     return JSON.parse(Uris)['Uris'];
    // },
    // 指定删除cookie，cookieList是一个数组，如果不传就删除所有cookie
    removeAllCookies(cookieList = []) {
        if (!cookieList.length) {
            cookieList = Object.keys(getAllCookies());
        }
        Object.keys(cookieList).forEach(function (key) {
            removeCookie(cookieList[key]);
            removeCookie(cookieList[key], {
                domain: window.idomain
            });
        });
    },
    // 打开loading加载效果（包括http和router跳转）
    openLoading() {
        Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '加载中...',
        });
    },
    // 关闭loading加载效果（包括http和router跳转）
    closeLoading() {
        Toast.clear();
    },
    // 调用原生iJsbridge方法
    // jsBridge(){
    //     return  new iJsBridge({ alert: Toast });
    // },
    // 正则验证规则
    regMethods({
        type,
        value
    }) {
        let result = {
            status: false,
            msg: ''
        };
        switch (type) {
            // 手机号
            case 'mobile':
                if (value.length != 11 || !(/^(1[0-9]{10})$/).test(value)) {
                    result = {
                        status: false,
                        msg: '请输入正确的手机号码！'
                    }
                } else {
                    result = {
                        status: true,
                        msg: '验证手机通过！'
                    }
                }
                break;
                // 密码
            case 'password':
                result = passwordFun(value);
                break;
        }
        return result;

        function passwordFun(value) {
            // 1.密码至少有8个字符
            // 2.密码至少包括以下四种字符类型中的三种：大写字母、小写字母、数字和特殊字符(-._@ 这四种特殊字符)。
            if (value.length < 8) {
                return {
                    status: false,
                    msg: '密码长度必须大于8'
                }
            }
            let score = 0;
            let regList = [/[A-Z]/, /[a-z]/, /[0-9]/, /[-._@]/]
            regList.forEach((item) => {
                if (item.test(value)) {
                    score++;
                }
            })
            if (score >= 3) {
                return {
                    status: true,
                    msg: '密码验证通过！'
                }
            } else {
                return {
                    status: false,
                    msg: '密码中必须包含大写字母、小写字母、数字、特殊字符串中的三种！'
                }
            }
        }
    },
    // 获取下一步路由
    nextRouter() {
        let routes = SmartStorage.get("router_queue");
        let previousRouter = SmartStorage.get("currentRouter");
        let routerIndex = routes.findIndex((item) => {
            return item.path == previousRouter
        })
        let currentRouter = routes[routerIndex + 1].path;
        SmartStorage.set("currentRouter", currentRouter);
        router.push(currentRouter)
    }
}

export default PublicMethod;