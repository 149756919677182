import { removeCookie,getCookie } from "tiny-cookie";
import { SmartStorage } from 'smart-core-util'
import iJsBridge from "smart-javascript-bridge";
import { Toast } from "vant";
import { httpService } from "../service/httpService";
import PublicMethod from "../plugins/public";

export default {
  data() {
    return {
      isFromMy: false,
      oldPhoneNumber: "",
      mobile: "",
      code: "",
      sessionId: "",
      getCodeText: "获取验证码",
      countDown: 120,
      gettingCode: false,
      srtInt: null,
    };
  },
  mounted: function() {
    PublicMethod.closeLoading();
    this.isFromMy = this.$route.query.isFromMy;
    this.getMe();
  },
  methods: {
    // 获取个人信息（手机号）
    async getMe() {
      let response = await httpService.getMe({});
      if (response.success && response.content) {
        this.oldPhoneNumber = response.content.phoneNumber;
      }
    },
    // 初始化，取消倒计时
    initSetInterval() {
      // 初始计时
      window.clearInterval(this.srtInt);
      this.gettingCode = false;
      this.countDown = 120;
    },
    // 返回按钮 (返回到ievent)
    onClickLeft() {
      if(getCookie('container')=='h5'){
        let Uris=SmartStorage.get('uris')
      let ieventUrl = Uris['webapp-ievent'];
      let url=ieventUrl+"/Home/Personal";
      console.log(url)
      window.open(url, '_self');
      return;
      }
      if (this.isFromMy) {
        var sendData = JSON.stringify({ handle: "reload_event_detail" });
        if(window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData);
        } else {
          let jsBridge = new iJsBridge({ alert: Toast });
          jsBridge.call({ method: "SMGeneral.setResultToNative", postData: sendData });
          jsBridge.close();
        }
      } else {
        removeCookie("token"); // 清除token从而登录
        removeCookie("token", { domain: window.idomain }); // 清除token从而登录
        SmartStorage.set("currentRouter", "login");
        this.$router.replace({ path: "/login" }); // 返回到login
      }
    },
    // 获取验证码
    async getCode() {
      if (!this.oldPhoneNumber) {
        Toast("原手机号码不正确！");
        return false;
      }

      this.counter();
      let params = {
        sessionId: "",
        mobiles: this.oldPhoneNumber,
        scope: 0,
        sign: 1,
        product: "会引擎",
      };
      let res = await httpService.sendCaptcha(params);
      if (res.success) {
        this.sessionId = res.content.sessionId;
      }
    },
    // 倒计时
    counter() {
      this.srtInt = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          this.initSetInterval();
        }
      }, 1000);
      this.gettingCode = true;
    },
    // 确定修改
    async sureBtn() {
      //验证手机号、验证码、密码
      let result = PublicMethod.regMethods({
        type: "mobile",
        value: this.mobile,
      });
      if (!result.status) {
        Toast(result.msg);
        return;
      }
      if (!this.mobile || this.mobile.length != 11) {
        Toast("请输入正确手机号码");
        return false;
      }
      if (!this.code) {
        Toast("请输入验证码");
        return false;
      }
      let params = {
        SessionId: this.sessionId,
        Mobile: this.mobile,
        Captcha: this.code,
      };
      PublicMethod.openLoading();
      let res = await httpService.certifyMobile(params);
      PublicMethod.closeLoading();
      if (res.success) {
        if(getCookie('container')=='h5'){
          let Uris=SmartStorage.get('uris')
        let ieventUrl = Uris['webapp-ievent'];
        let url=ieventUrl+"/Home/Personal";
        console.log(url)
        window.open(url, '_self');
        return;
        }
        var sendData = JSON.stringify({ handle: "reload_event_detail" });
        if(window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData)
        } else {
          let jsBridge = new iJsBridge({ alert: Toast });
          jsBridge.call({ method: "SMGeneral.setResultToNative", postData: sendData });
          jsBridge.close();
        }
      }
    },
  },
};
