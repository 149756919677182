import { SmartStorage } from 'smart-core-util'
import { httpService } from '../service/httpService'
import {
    getCookie
} from 'tiny-cookie'
var enviroment = process.env.VUE_APP_ENV; // 线上

export default class RouterQueue {
	static install() {
		return new Promise(function (resolve) {
            let tenant=getCookie("container")=='h5'?'rocheWeChat':SmartStorage.get('tenant')
            let url = `https://spkg.smartmice.cn/configuration/iapp-config/${tenant}/${enviroment}/routerQueue.json`
            let params = {
                params: {
                    rnd: new Date().getTime()
                }
            }

            httpService.Get(url, params).then(response => {
                RouterQueue.setRouterQueue(response.routerList || [])

                resolve(response)
            }).catch(error => {
                resolve(error)
            })
        })
    }
    // 设置路由队列
    static setRouterQueue(routerList = {}) {
        SmartStorage.remove("currentRouter");
        SmartStorage.set("router_queue", routerList)
    }
}