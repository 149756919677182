import {
    httpService
} from '../service/httpService'
import {
    SmartStorage
} from 'smart-core-util'
import PublicMethod from '../plugins/public'

export default {
    async beforeRouteEnter(to, from, next) {
        console.log(from, to);
        let result = await httpService.getrecord();
        if (!result.data.isCompleted) {
            let Uris = SmartStorage.get('uris');

            PublicMethod.closeLoading();
            window.document.location = `${Uris['webpart-isurvey']}?tenant=${SmartStorage.get('tenant')}&fromOtherSite=iaccount`;
            next();
        } else {
            PublicMethod.nextRouter()
        }
    },
    data() {
        return {
            checked: false,
        }
    },
    mounted() {
        this.checked = true;
    }
}