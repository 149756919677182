import { SmartHttp } from 'smart-core-util'

const request = new SmartHttp({
    baseURL: process.env.VUE_APP_GATEWAY, 
    // baseURL: "https://a1-gateway-test.roche.com.cn",
    // baseURL: "https://a1-gateway.roche.com.cn",
    disableLoadingAnimation: true
}).request;

export const httpService = {
    // get公共方法
    Get: (url, params) => {
        return request.get(url, params);
    },
    // tpm
    getTpm:(params) => {
        return request.post('/api/tpm/cfg/getsettings',params);
    },
    // 登陆
    login: async (params,headers) => {
        return await request.post("/identity/login" , params, headers);
    },
    // 获取个人信息
    getMe: (params) => {
		return request.post("/foundation/passport/IUserService/GetMe", params);
	},
    // 登陆成功后，merck判断当前人员是否存在 （只限merck）
    Authenticate: () => {
        let headers = {
            "Content-Type": "application/x-www-form-urlencoded"
        };
        return request.post("/api/tenants/merck/Login/Authenticate", {headers});
    },
    // 是否授权赋权（只限roche）
    getRoleCheck: () => {
        return request.get('/api/tenants/roche/tenantconfig/get');
    },
    // 获取授权赋权的地址（只限roche）
    getRoleCheckUrl: () => {
        return urls['webapp-idelegate'] + '/applyJurisdiction';
    },
    // 支付小助手--答题（只限pfizer）
    getrecord:() => {
        return request.get('/api/smartpay/trainning/getrecord');
    },
    // 获取验证码(绑定手机,修改手机)
    sendCaptcha:(params) => {
        return request.post("/foundation/aliyun/api/Sms/SendCaptcha", params);
    },
    // 绑定手机号
    certifyMobile:(params) => {
        return request.post("/foundation/passport/IUserService/CertifyMobile", params);
    },
    // 免责声明
    getMyLastUnread: (url) => {
        return request.post(url, {}, { noblock: true });
    },
    // 确认免责声明
    read: (url,params) => {
        return request.post(url, params);
    },
    // 验证密码 90天过期(只限msd)
    async isChangePassword(){
        return request.post("/api/tenants/roche/Disclaimer/Read",{});
    },
    // 注册设备
    async registerDeviceAsync(params){
        return request.post("/api/service/notification/MqPushDevice/Insert",params);
    },
    // 修改密码
    changePassword: (params) => {
        return request.post("/foundation/passport/IUserService/ChangePassword", params);
    },
    // 修改密码(使用 原密码修改！)
    changePasswordByOldPassword(params){
        return request.post("/api/foundation/user/ChangePassword",params);
    },
    // dsm 初次登录修改密码
    isChangePassword2:(params) => {
        return request.post("/api/tenants/dsm/User/IsChangePassword",params);

    },
}
