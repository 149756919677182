import {
    Toast
} from 'vant';
import {
    getCookie,
    setCookie
} from 'tiny-cookie'
import JSEncrypt from 'jsencrypt'
import {
    SmartStorage
} from 'smart-core-util'
import {
    httpService
} from '../service/httpService'
import PublicMethod from '@/plugins/public.js';
import SetUris from "@/plugins/set-uris.js"

export default {
    async beforeRouteEnter(to, from, next) {
        // 这里判断是否需要当前 步骤
        let token = getCookie("token");
        if (token) {
            setCookie('NotVerified', true, { domain: window.idomain})
            PublicMethod.nextRouter()
        } else {
            next();
        }
    },
    data() {
        return {
            logUri: '',
            inputEnabled: false,
            ssoEnabled: false,
            ssoUrl: '',
            ssoBtnName: "",
            pwdBtnName: "",
            userName: '',
            password: '',
            version: '',
            platform: '',
            tenant: ''
        }
    },
    async created() {
        this.version = SmartStorage.get("version") || '';
        this.platform = SmartStorage.get("platform") || 'app';
        this.tenant = SmartStorage.get("tenant") || '';
        await this.getLoginConfig();
    },
    methods: {
        // 获取 登陆配置信息
        async getLoginConfig() {
            let params = {
                "collection": 'cfg-iaccount-login',
                "useCaching": true,
                "cachingKey": "",
                "filter": {
                    "platform": this.platform,
                    "tenantCode": this.tenant
                },
                "projection": {
                    "tenantCode": 1,
                    "sso": 1,
                    "logoUri": 1,
                    "UI": 1,
                    "version": 1
                }
            }
            let result = await httpService.getTpm(params);
            if (result.success && result.content) {
                this.logUri = result.content.logoUri; // logo图
                this.inputEnabled = result.content.ui.allowPwd; // 账号密码登陆
                this.ssoEnabled = result.content.ui.allowSso; // sso登陆
                this.ssoUrl = result.content.sso; // sso登陆地址
                this.ssoBtnName = result.content.ui.ssoBtnName || "UIDS 登录";
                this.pwdBtnName = result.content.ui.pwdBtnName || "登 录";

            }
            PublicMethod.closeLoading();
        },
        // 登陆按钮
        loginBtn() {
            this.login();
        },
        // 登陆 ajax
        async login() {
            if (!this.userName || !this.password) {
                Toast("账号密码不能为空");
                return;
            }

            PublicMethod.openLoading();

            let params = {
                client_id: "app-24",
                client_secret: "smartx",
                grant_type: "password",
                userName: this.userName,
                password: this.password
            }
            const encryptor = new JSEncrypt(); // 创建加密对象实例
            //之前ssl生成的公钥，复制的时候要小心不要有空格
            const pubKey = `-----BEGIN PUBLIC KEY-----
                            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjnk/X6gI+TKMQsiYDoiq
                            UlWSpEfMdsjztk0k/ygKYo7HUky90YmlJqpLTOLIhKnBYX3g4cPMuynWntAoXpM9
                            G+Wr5PhMcYvG7a21VOYy6mIizABpDhXbDp3SYlnVS5tIK/8rAXytKhK28pPgyOTL
                            /ENBKMD+mBZc4ktjnyVKVv7u0RaiYAOVjp9ZbnAzWXFK2nghMGsDVriWkK66/+h5
                            TAX8iSxMXHeOPmzGfk7fZgpxW5dzwActVsraCTwhUHCA9yojM4H4ONO4MvQbP58T
                            /Nv0KSAbdQkHEL8WhfsKhp55WSypmzntt7bEysvPIS4q/IQTRN69h4FMQIP7mj0I
                            /wIDAQAB
                            -----END PUBLIC KEY-----`;
            encryptor.setPublicKey(pubKey); //设置公钥
            let rsaPassWord = encryptor.encrypt(JSON.stringify(params)); // 对内容进行加密

            let headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                "tenant": this.tenant
            }

            let response = await httpService.login(params, {
                headers
            });

            if (response && (response.success || response.access_token)) {
                let setting = {
                    "token": "access_token",
                    "organization_type": "organization_type",
                    "userId": "userId",
                    "tenant_id": "tenant_id",
                    "tenant": "tenant_code",
                    "role_codes": "role_codes",
                    "supplierId": "supplierId",
                    "PhoneNumberConfirmed": "PhoneNumberConfirmed",
                    "pilot": "pilot",
                }
                let config = {
                    expires: (response.content || response).expires_in + 's',
                    domain: window.idomain
                };
                SmartStorage.set("tenant", (response.content || response).tenant_code);
                SmartStorage.set("currentRouter", "/login");
                setCookie('NotVerified', true, { domain: window.idomain})
                await this.appendCookie(response.content || response, setting, config);
                // 解析token并获取tenant_code
                // let result = this.publicMethod.analysisToken(response.access_token);
                // let tenant_code = result.tenant_code;
                // if(result){
                //     setCookie('tenant_code',tenant_code,{expires: response.expires_in+'s',domain:window.idomain});
                // }

                SetUris.install().then(() => {
                    PublicMethod.nextRouter()
                });
                return;
            } else if (response.AccessFailedCount) {
                Toast(
                    `${response.error_description}，密码已输入错误${response.AccessFailedCount}次，输错${response.LimitFailedCount}次账号将被锁定${response.LockMinute}分钟`
                );
            } else if (response.error_description) {
                Toast(response.error_description);
            }
            else {
                PublicMethod.closeLoading();
            }
        },
        // 使用sso登陆
        ssoLogin() {
            window.location.href = this.ssoUrl;
        },
        // 添加到 cookie(注意：因为涉及多个站点共享cookie问题，所以需要设置主域名相同)
        async appendCookie(data, setting, config) {
            for (var i in setting) {
                let value = data[setting[i]];
                if(value) setCookie(i, data[setting[i]], config);
                continue;
            }
        },
        // 去忘记密码
        forgetPsw() {
            this.$router.push('/forgetPassword');
        },
    }
}
