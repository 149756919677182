<template>
    <van-nav-bar class="header" @click-left="onClickLeft">
      <div slot="left" class="back">
        <van-icon name="arrow-left" />
        返回
      </div>
      <div slot="title" style="color: #ffffff">授权赋权</div>
    </van-nav-bar>
</template>

<script>
import { httpService } from '../service/httpService.js'
import { SmartStorage } from 'smart-core-util'
import PublicMethod from '@/plugins/public.js';
import { removeCookie } from 'tiny-cookie';
import { Dialog } from 'vant';

export default {
    async beforeRouteEnter(to, from, next) {
      console.log(SmartStorage.get("platform"))
      if(SmartStorage.get("platform")=='app'){
        let response = await httpService.getRoleCheck();
        if(response && response.content){
            PublicMethod.nextRouter();
        }else{
            next();
        }
      }else{
        PublicMethod.nextRouter();
      }
        
    },
    created() {
        PublicMethod.closeLoading();
    },
    mounted() {
        this.onClick();
    },
    methods: {
        onClick() {
            Dialog.alert({
                title: '提示',
                message: '你没有会议组织者权限，请进入AMS申请权限',
            }).then(() => {
                this.onClickLeft();
            });
        },
        // 返回按钮
        onClickLeft(){
            removeCookie('token');
            removeCookie("token",{ domain: window.idomain }); 
            SmartStorage.set("currentRouter", 'login');
            this.$router.push('/login');
        },
    }
}
</script>

<style scoped lang="less">
  .header {
    background: var(--themeColor);
    color: white;
    .back {
      display: flex;
      align-items: center;
    }
  }
  .van-nav-bar .van-icon {
    color: #ffffff;
  }
</style>