<template>
<div>
  <div v-if="!checked" class="loadingRead">
    <img class="loadingImg" src="../assets/loading.gif" alt="">
  </div>
  <div v-if="checked"></div>
</div>
</template>
<script>
import survey from "../controllers/survey.js";
export default {
  extends: survey
};
</script>

<style>
.disabledBtn{
  background:#dedede;
}
h1{
  font-size:0.2rem;
}
h2{
  font-size:0.16rem;
  margin-bottom:0.04rem;
}
.tips{
  font-size:0.14rem;
  margin-top:0;
}
.alignLeft{
  text-align:left;
}
.alignCenter{
  text-align:center;
}
.content {
  font-size:0.4rem;
  position: relative;
  top: 0.4rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding:0 0.2rem;
}
.loadingRead{
  text-align:center;
  padding-top:1rem;
}
.loadingImg{
  max-width:200px;
  max-height:200px;
  width:80%;
  height:80%
}
.contentHtml{
  padding:0.2rem 0;
  font-size:0.2rem;
}
.footer{
  margin:0.4rem auto;
  text-align: center;
}
.footerBtn{
  min-width:0.8rem;
  height:0.4rem;
  line-height:0.4rem;
}
.footerBtnGroup{
  margin:1rem auto 0;
  text-align:center;
  font-size:0.2rem;
}
</style>
