import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import AppInitialization from "@/plugins/app-initialization.js"
import RouterQueue from "@/plugins/router-queue.js"
import SetUris from "@/plugins/set-uris.js"
import PublicMethod from './plugins/public'
import { SmartRem, SmartStorage } from 'smart-core-util'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.config.productionTip = false

PublicMethod.openLoading();

// 是否需要先清除cookie
let url = window.location.search.substring(1);
if(url.indexOf('removeCookie') > -1){
    PublicMethod.removeAllCookies();
    SmartStorage.clear();
}
console.log(url)
if(url.indexOf("gray") > -1){
  setCookie('gray', true)
}
// 绑定全局方法
// Vue.prototype.publicMethod = publicMethod;

// 引入rem布局
SmartRem.install()

Promise.all([AppInitialization.install(), RouterQueue.install(), SetUris.install()]).then(() => {
    new Vue({
        router,
        render: (h) => h(App),
    }).$mount('#app')
});