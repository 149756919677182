<template>
    <div class="bindPhone">
        <van-nav-bar id="header" @click-left="onClickLeft">
            <div slot="left">
                <van-icon name="arrow-left" class="colorF"/>
                返回
            </div>
            <div slot="title" class="colorF" style="color:#ffffff;">免责声明</div>
        </van-nav-bar>

        <div class="region">
            <div class="content" v-html="model.content">
                免责声明内容
            </div>

            <h3 @click="accept()">
                <input type="checkbox" id="selectCheck"/>
                <label id="label">在此勾选，确认您已充分阅读、理解并同意上述内容。</label>
            </h3>

            <div class="footer">
                <van-button class="btn btn-false" type="default" @click="readAsync(false)">取 消</van-button>
                <van-button class="btn btn-sure" :class="{disabledBtn:!accepted}" v-bind:disabled="!accepted" @click="readAsync(true)">确 定</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import readDisclaimer from '../controllers/readDisclaimer.js'
export default {
    extends:readDisclaimer
}
</script>

<style scoped lang="less">
// @import "@/assets/public.less";
// @import '@/assets/variable.less';
#header{
    // @include backgroundColor();
      background: var(--themeColor);

    color:#ffffff;
}
.van-nav-bar .van-icon{
    color:#ffffff;
}
.region{
    margin: 0.2rem;
    vertical-align: middle;
    text-align: left;
}

.footer{
    margin:0.4rem auto;
    text-align: center;
    .disabledBtn{
        background:#dedede;
    }
    .btn{
        min-width:0.8rem;
        height:0.4rem;
        line-height:0.4rem;
    }
    .btn-false{
        // border:0.01rem solid $colorE;
    }
    .btn-sure{
        margin-left:0.2rem;
        color:#ffffff;
        background: var(--themeColor);
        // @include backgroundColor();
    }
}


</style>