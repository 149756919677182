<template>
<div>
    <van-nav-bar class="changepwdHeader" @click-left="onClickLeft">
        <div slot="left">
            <van-icon name="arrow-left" class='color-f'/>
            返回
        </div>
        <div slot="title" class='color-f'>修改密码</div>
    </van-nav-bar>
        
    <van-cell-group>
        <van-field type="password" v-model="old" clearable label="原始密码" placeholder="请输入原始密码"/>
        <van-field type="password" v-model="updated" clearable label="新密码" placeholder="请输入新密码"/>
        <van-field type="password" v-model="confirm" clearable label="确认新密码" placeholder="请输入确认新密码"/>
    </van-cell-group>

    <div class="btnList">
        <div class="btnList_sure text-align-center colorF" size="large" @click="submitPassword">确 定</div>
    </div>
</div>
</template>

<script>
import changePwd from "../controllers/changePassword.js";
export default {
  extends: changePwd
};
</script>

<style scoped lang="less">
// @import "~@/assets/public.less";
// @import "@/assets/variable.less";
.changepwdHeader{
    background: var(--themeColor);
    color: #ffffff;
    .color-f{
        color: #ffffff;
    }
}
.btnList{
    padding:0.2rem;
    .btnList_sure{
    text-align: center;
    color: #ffffff;
    padding:0.1rem 0;
    border-radius: 0.04rem;
    background: var(--themeColor);
}
}

</style>
