<template>
    <div class="content">
        <p class="title">
            <span>一</span>
            <span>切</span>
            <span>就</span>
            <span>绪</span>
        </p>
        <p class="child">将带你开启智会之旅</p>
    </div>
</template>

<script>
import welcome from "@/controllers/welcome.js";
export default {
  extends: welcome
};
</script>

<style scoped lang="less">
// @import "@/assets/public.less";
// @import '@/assets/variable.less';
@-webkit-keyframes masked-animation {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -100% 0;
    }
}
.content {
    font-size: 0.2rem;
    padding-top: 1rem;
    text-align: center;
    background-image: -webkit-linear-gradient(
        left,
        #5195f5,
        #ccc 25%,
        #5195f5 50%,
        #ccc 75%,
        #5195f5
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-background-size: 200% 100%;
    -webkit-animation: masked-animation 1s infinite linear;
    .title {
        font-size: 0.3rem;
        font-weight: 700;
        text-align: center;
        color: #5195f5;
        margin-bottom: 0.2rem;
    }
    .child {
        font-size: 0.2rem;
        text-align: center;
        color: #777777;
    }
}
</style>