<template>
  <div class="errorPage">
    <van-nav-bar class="header" @click-left="onClickLeft">
      <div slot="left" class="back">
        <van-icon name="arrow-left" />
        返回
      </div>
      <div slot="title" style="color: #ffffff">异常提示</div>
    </van-nav-bar>

    <div class="content">
      <p>
        {{ errorCodeText }}
      </p>
    </div>
  </div>
</template>

<script>
import errorPage from "@/controllers/errorPage.js";
export default {
  extends: errorPage,
};
</script>

<style scoped lang="less">
.errorPage {
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header {
    background: var(--themeColor);
    color: white;
    .back {
      display: flex;
      align-items: center;
    }
  }
  .van-nav-bar .van-icon {
    color: #ffffff;
  }
}
</style>
