import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);

const routers = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: () => import('../views/ForgetPassword.vue')
    },
    {
        path: '/changepwd',
        name: 'changepwd',
        component: () => import('../views/ChangePassword.vue'),
    },
    {
        path: '/roleCheck', // 罗氏 授权 赋权
        name: 'roleCheck',
        component: () => import('../views/RoleCheck.vue'),
    },
    {
        path: '/survey',
        name: 'survey',
        component: () => import('../views/survey.vue'),
    },
    {
        path: '/bindPhone',
        name: 'bindPhone',
        component: () => import('../views/BindPhone.vue'),
    },
    {
        path: '/changePhone',
        name: 'changePhone',
        component: () => import('../views/ChangePhone.vue'),
    },
    {
        path: '/readDisclaimer',
        name: 'readDisclaimer',
        component: () => import('../views/ReadDisclaimer.vue'),
    },
    // {
    //     path: '/alipay',
    //     name: 'alipay',
    //     component: () => import('../views/Alipay.vue'),
    // },
    {
        path: '/welcome',
        name: 'welcome',
        component: () => import('../views/Welcome.vue'),
    },
    // {
    //     path: '/emptyDataPage',
    //     name: 'emptyDataPage',
    //     component: () => import('../views/emptyDataPage.vue'),
    // },
    {
        path: '/errorPage',
        name: 'errorPage',
        component: () => import('../views/errorPage.vue'),
    },
    // {
    //     path:'*',  // 匹配路由，当手动输入地址栏中的路由没有时，统一跳转到指定component
    //     component: () => import('../views/Error.vue'),
    // }
]

const router = new Router({
    mode: 'history',
    routes: routers
})

// router.beforeEach( async (to,from,next) => {
    // 根据路由设置title
    // if(to.meta.title) {
    //     document.title = to.meta.title;
    // }

    // next();
// })

const originalPush = Router.prototype.push

Router.prototype.push = function push(location, onResolve, onReject) {

if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)

return originalPush.call(this, location).catch(err => err)

}

export default router
