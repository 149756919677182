import iJsBridge from 'smart-javascript-bridge'
import { Toast } from 'vant';
import { getCookie,removeCookie } from "tiny-cookie";
import {
    httpService
} from '../service/httpService'
import PublicMethod from '@/plugins/public.js';

export default {
    async beforeRouteEnter(to, from, next) {
        // dsm首次登陆成强制修改密码 
        let response = await httpService.isChangePassword2();

        if(!response || !response.success) {
            PublicMethod.closeLoading();
            return;
        } 

        if(response.success && response.content.change){
            this.toast('请修改密码！')
            this.$cookies.setCookie('step', '/changepwd');
            next()
        }else{
            console.log("111", response)
            next(() => {
                PublicMethod.nextRouter()
                // that.$router.push('/readDisclaimer');
            })
        }
    },
    data(){
        return{
            old:'',
            updated:'',
            confirm:'',
            isFromMy:false,
        }
    },
    mounted:function(){
        this.isFromMy = getCookie("isFromMy") || '';
        // this.publicMethod.closeLoading();
    },
    methods:{
        // 提交 修改密码
        submitPassword() {
            //验证手机号、验证码、密码
            let resultMobile = PublicMethod.regMethods({'type':'password','value':this.updated})
            if(!resultMobile.status){
                Toast(resultMobile.msg)
                return;
            }
            if (this.updated != this.confirm) {
                Toast("新密码和确认新密码不同！");
                return false;
            }
            let params = {
                old:this.old,
                new:this.updated,
                confirm:this.confirm
            }
            httpService.changePasswordByOldPassword(params)
            .then(res => {
                if (res.success) {
                    Toast("修改密码成功，请重新登录！");
                    removeCookie('token')
                    removeCookie("token",{ domain: window.idomain }); 
                    SmartStorage.set("currentRouter", 'login');
                    this.$router.push('/login');
                } else {
                    if (res.msg) {
                        //请填写新密码;请确认密码
                        Toast("" + res.msg);
                    } else {
                        Toast("系统错误");
                    }
                }
            })
        },
        // 返回按钮
        onClickLeft(){
            if(this.isFromMy){
                let sendData = JSON.stringify({handle: "reload_event_detail"});
                if(window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData);
                } else {
                    let jsBridge  = new iJsBridge({ alert: Toast })
                    jsBridge.call({method: 'SMGeneral.setResultToNative', postData:sendData});
                    jsBridge.close();
                }
            }else{
                this.$router.back();
            }
        }
    }
}
