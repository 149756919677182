<template>
  <div class="bindPhone">
    <van-nav-bar class="header" @click-left="onClickLeft">
      <div slot="left" class="back">
        <van-icon name="arrow-left" />
        返回
      </div>
      <div slot="title" style="color: #ffffff">绑定手机</div>
    </van-nav-bar>

    <div>
      <van-cell-group>
        <van-field
          v-model="mobile"
          clearable
          label="手机号码"
          placeholder="请输入手机号码"
        />
        <van-field
          v-model="code"
          clearable
          label="动态验证码"
          placeholder="请输入验证码"
        >
          <span v-if="!gettingCode" class="btn" slot="button" @click="getCode"
            >获取验证码</span
          >
          <span v-if="gettingCode" class="getCode" slot="button">
            {{ countDown }}
            <span>S</span>
          </span>
        </van-field>
      </van-cell-group>
    </div>

    <div class="btnList">
      <div class="btnList_sure" size="large" @click="bindBtn">确 定</div>
    </div>
  </div>
</template>

<script>
import bindPhone from "../controllers/bindPhone.js";
export default {
  extends: bindPhone,
};
</script>

<style scoped lang="less">
.bindPhone {
  .header {
    background: var(--themeColor);
    color: white;
    .back {
      display: flex;
      align-items: center;
    }
  }
  .van-nav-bar .van-icon {
    color: #ffffff;
  }
}
.btn {
  display: inline-block;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  padding: 0 0.04rem;
  font-size: 0.12rem;
}
.btnList {
  padding: 0.2rem;
  .btnList_sure {
    text-align: center;
    padding: 0.1rem 0;
    border-radius: 0.04rem;
    color: #ffffff;
    background: var(--themeColor);
  }
}

</style>
