import { getCookie,getAllCookies, setCookie } from 'tiny-cookie';
import iJsBridge from 'smart-javascript-bridge'
import { Toast } from 'vant';
// Vue.use(Toast);
import { httpService } from '../service/httpService.js';
import PublicMethod from '../plugins/public'
import {
    SmartStorage
} from 'smart-core-util'

export default {
    async beforeRouteEnter(to, from, next) {
        // 这里判断是否需要当前 步骤
        // 验证密码 90天过期(只限msd)
        let tenant = getCookie('tenant')
        if(tenant == 'msd'){
            let response = await httpService.isChangePassword();
            if(response.success && response.content){
                Toast('密码已过期，请修改！')
                next('/');
                return;
            }
        }
        PublicMethod.closeLoading();
        next();
    },
    mounted:function(){
        this.entry();
    },
    methods:{
        entry: async function () {
            var container = SmartStorage.get("container");
            setCookie('NotVerified', false, { domain: window.idomain})
            switch (container) {
                case "simulator":
                    this._simulator();
                    break;
                case "browser":
                    this._browser();
                    break;
                case "native":
                default:
                    await this.getDeviceAsync();
                    await this.nativeSave();
                    await this._native();
                    break;
            }
        },
        // pc端调用iaccount
        _simulator: function () {
            var cookieData = getAllCookies();
            cookieData.login_pass = 1; // 登录成功，用在罗氏web端判断是否通过授权申请
            top.postMessage(
                {
                    from: "simulator",
                    data: cookieData
                },
                "*"
            );
        },
        // 浏览器模拟
        _browser: async function () {
            var iEventHost = 'webapp-ievent';
            // 获取ievent地址
            let Uris = SmartStorage.get('uris');
            let hostUrl = `${Uris[iEventHost]}?tenant=${SmartStorage.get("tenant")}`;
            
            window.open(hostUrl);
        },
        // app调用iaccount
        _native: async function () {
            // await iThread.sleep(2000);
            var iEventHost = 'webapp-ievent';
            // 获取ievent地址
            let Uris = SmartStorage.get('uris');
            let hostUrl = `${Uris[iEventHost]}?tenant=${SmartStorage.get("tenant")}`;

            if (window.flutter_inappwebview) {
                //TODO cookie
                let params = {
                    "url":hostUrl,
                    // "postMessage":{},
                    "cookies":getAllCookies()
                }
                window.flutter_inappwebview.callHandler("SmGeneral.openNewWebSiteAndRemove", JSON.stringify(params));
            } else {
                let jsBridge  = new iJsBridge({ alert: Toast })
                jsBridge.openWebapp({
                    loadUrl: hostUrl,
                });
                jsBridge.close();
            }
        },
        // 调用原生 保存数据
        nativeSave: async function(){
            var cookieData = getAllCookies();
            let params = {
                overCheck:true,
            };
            Object.assign(params,cookieData);
            if(window.flutter_inappwebview) {
                let p = {
                    'key':'webDirctOpenCookie',
                    'value': JSON.stringify(params)
                }
                window.flutter_inappwebview.callHandler("SmStorage.setString", JSON.stringify(p));

            } else {
                let jsBridge  = new iJsBridge({ alert: Toast })
                jsBridge.call({method: "SMDataStorage.setItem", postData:params});
            }
        },
        // 获取设备信息
        getDeviceAsync: async function () {
            // try {
                var returnValue;
                if(window.flutter_inappwebview) {
                   returnValue = window.flutter_inappwebview.callHandler("SmStorage.getDeviceInfo","");
                } else {
                    let jsBridge  = new iJsBridge({ alert: Toast })
                    returnValue = jsBridge.call({
                        method: "SMDataStorage.getDevice",
                        callback: this.RegisterDeviceAsync
                    });
                }
                if (returnValue) {
                    await this.RegisterDeviceAsync(returnValue);
                }
            // } catch (e) {

            // }
        },
        // 注册设备
        RegisterDeviceAsync: async function (result) {
            // alert("RegisterDeviceAsync:", JSON.stringify(result));
            result = typeof(result) == "string" ? JSON.parse(result) : result;
            let input = null
            if (window.flutter_inappwebview) {
                let regId = await window.flutter_inappwebview.callHandler("SmStorage.getString","registrationId");
                input = {
                    extUserId: regId,
                    deviceType: result.type,
                    deviceData: result.model
                }
            } else {
                input = {
                    extUserId: result.registrationId,
                    deviceType: result.deviceType,
                    deviceData: result.deviceData
                }
            }
           
            await httpService.registerDeviceAsync(input)
        },
    }
}