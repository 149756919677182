import { getCookie, removeCookie } from 'tiny-cookie';
import { SmartStorage } from 'smart-core-util'
import {
    httpService
} from '../service/httpService'
import PublicMethod from '../plugins/public'
import iJsBridge from 'smart-javascript-bridge'
import { Toast } from 'vant';

export default {
    async beforeRouteEnter(to, from, next) {
        // 这里判断是否需要当前 步骤
        let PhoneNumberConfirmed = getCookie("PhoneNumberConfirmed") == "true" || getCookie("PhoneNumberConfirmed") == "True";
        if(PhoneNumberConfirmed){
            PublicMethod.nextRouter()
        }else{
            PublicMethod.closeLoading();
            next();
        }
    },
    data(){
        return{
            mobile: "",
            code: "",
            sessionId:'',
            countDown: 120,
            gettingCode: false,
            srtInt: null,
            isFromMy:false,  // 判断绑定手机号(默认)？还是修改手机号？
        }
    },
    created() {
        this.isFromMy = this.$route.query.isFromMy;
    },
    methods:{
        // 初始化，取消倒计时
        initSetInterval() {
            // 初始计时
            window.clearInterval(this.srtInt);
            this.gettingCode = false;
            this.countDown = 120;
        },
        // 获取验证码
        async getCode() {
            // 验证手机号
            let result = PublicMethod.regMethods({'type':'mobile','value':this.mobile})
            if (!result.status) {
                Toast(result.msg)
                return;
            }

            this.counter();

            let params = {
                sessionId:'',
                mobiles: this.mobile,
                scope:0,
                sign:1,
                product: '会引擎'
            }
            
            let res = await httpService.sendCaptcha(params);
            if (res.success) {
                this.sessionId = res.content.sessionId;
            }
        },
        // 发送验证码 倒计时
        counter() {
            this.srtInt = setInterval(() => {
                if (this.countDown > 0) {
                    this.countDown--;
                } else {
                    this.initSetInterval();
                }
            }, 1000);
            this.gettingCode = true;
        },
        // 绑定手机号
        async bindBtn(){
            //验证手机号、验证码、密码
            if (!this.code) {
                Toast("请输入验证码");
                return false;
            }
            const _this = this;
            let params = {
                SessionId:this.sessionId,
                Mobile:this.mobile,
                Captcha:this.code
            }
            PublicMethod.openLoading();
            let res = await httpService.certifyMobile(params)
            PublicMethod.closeLoading();
            if (res.success) {
                Toast("绑定手机号成功！");
                this.goAfter();
            }
            // .catch(error => {
            //     console.log(error);
            // });
        },
        // 绑定手机号，成功后执行方法
        goAfter(){
            // 判断isFromMy
            if(this.isFromMy){
                var sendData = JSON.stringify({handle: "reload_event_detail"});
                if(window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData);
                } else {
                    let jsBridge  = new iJsBridge({ alert: Toast })
                    jsBridge.call({method: 'SMGeneral.setResultToNative', postData:sendData});
                    jsBridge.close();
                }
            }else{
                PublicMethod.nextRouter()
            }
        },
        // 返回按钮
        onClickLeft(){
            // 判断isFromMy
            if(this.isFromMy){
                var sendData = JSON.stringify({handle: "reload_event_detail"});
                if(window.flutter_inappwebview) {
                    window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData);
                } else {
                    let jsBridge  = new iJsBridge({ alert: Toast })
                    jsBridge.call({method: 'SMGeneral.setResultToNative', postData:sendData});
                    jsBridge.close();
                }
            }else{
                removeCookie('token');
                removeCookie("token",{ domain: window.idomain }); 
                SmartStorage.set("currentRouter", 'login');
                this.$router.push('/login');
            }
        },
    }
}