import { SmartStorage } from 'smart-core-util'
import {
    getCookie,
    setCookie
} from 'tiny-cookie'
import { httpService } from '../service/httpService'
var enviroment = process.env.VUE_APP_ENV; // 线上
export default class SetUris {
	static install() {
		return new Promise(function (resolve) {
            let tenant=getCookie("container")=='h5'?'rocheWeChat':SmartStorage.get('tenant')
            let url = `https://spkg.smartmice.cn/configuration/iapp-config/${tenant}/${enviroment}/uris.json`
            let params = {
                params: {
                    rnd: new Date().getTime()
                }
            }

            httpService.Get(url, params).then(response => {
                SetUris.setUris(response.uris || {})

                resolve(response)
            }).catch(error => {
                resolve(error)
            })
        })
    }
    // 设置Uris
    static setUris(uris = {}) {
        let config = {
            expires: 31104000 + 's',
            domain: window.idomain
        };

        SmartStorage.set("uris", uris)
        setCookie("Uris", JSON.stringify({"Uris": uris}), config);
    }
}
