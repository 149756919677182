import { SmartStorage } from 'smart-core-util'
import { httpService } from '../service/httpService'
import {
    setCookie,getCookie
} from 'tiny-cookie'
var enviroment = process.env.VUE_APP_ENV; // 线上
export default class AppInitialization {
	static install() {
        this.setiDomain();
        this.urlParamToStorage();
		return new Promise(function (resolve) {
            let tenant=getCookie("container")=='h5'?'rocheWeChat':SmartStorage.get('tenant')
            let url = `https://spkg.smartmice.cn/configuration/iapp-config/${tenant}/${enviroment}/base.json`
            let params = {
                params: {
                    rnd: new Date().getTime()
                }
            }

            httpService.Get(url, params).then(response => {
                console.log(typeof(response))
                AppInitialization.setTheme(response.themeColors)
                AppInitialization.setTitle(response.titleTxt)

                resolve(response)
            }).catch(error => {
                resolve(error)
            })
        })
    }
    // 设置皮肤颜色
    static setTheme(colorList) {
        console.log("setTheme: ", colorList)
        colorList.map(item => {
            document.body.style.setProperty(item.attribute, item.value)
        })
    }
    // 设置title
    static setTitle(txt = "会引擎") {
        document.title = txt
    }

    // url 参数转 storage
    static urlParamToStorage() {
        let url = window.location.search.substring(1);
        if(url){
            let urlParams = url.split('&');
            urlParams.forEach(item => {
                let temp = item.split('=');
                SmartStorage.set(temp[0], temp[1]);

                setCookie(temp[0], temp[1], {
                    expires: '31104000s',
                    domain: window.idomain
                })
            })
        } 
    }

    static setiDomain() {
        let domain = document.domain;
        const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式 
        let isIP = re.test(domain);
        let isLocalhost = domain == "localhost";
        console.log('document domain:', domain);
        domain = (isIP || isLocalhost) ? domain : domain.substring(domain.indexOf('.') + 1);
        window.idomain = domain;
    }
}