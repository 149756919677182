import { Toast } from 'vant';
import {
    httpService
} from '../service/httpService'
import PublicMethod from '../plugins/public'

export default {
    data(){
        return{
            mobile: "",
            code: "",
            newPassword: "",
            confirmPassword: "",
            sessionId:'',
            getCodeText: "获取验证码",
            countDown: 120,
            gettingCode: false,
            srtInt: null
        }
    },
    mounted:function(){
    },
    methods:{
        // 初始计时
        initSetInterval() {
            window.clearInterval(this.srtInt);
            this.gettingCode = false;
            this.countDown = 120;
        },
        // 获取验证码
        getCode() {
            if (!this.mobile) {
                Toast("请输入正确手机号码");
                return false;
            }
            let resultMobile = PublicMethod.regMethods({'type':'mobile','value':this.mobile})
            if(!resultMobile.status){
                Toast(resultMobile.msg)
                return;
            }
            this.counter();
            let params = {
                sessionId:'',
                mobiles: this.mobile,
                scope:0,
                sign:1,
                product: '会引擎'
            }
            httpService.sendCaptcha(params).then(res => {
                if (res.success) {
                    this.sessionId = res.content.sessionId;
                }
            })
            // .catch(error => {
            //     console.log(error);
            // });
        },
        // 发送验证码，倒计时
        counter() {
            this.srtInt = setInterval(() => {
                if (this.countDown > 0) {
                    this.countDown--;
                } else {
                    this.initSetInterval();
                }
            }, 1000);
            this.gettingCode = true;
        },
        // 提交 修改密码
        submitPassword() {
            //验证手机号、验证码、密码
            var _this = this
            let resultMobile = PublicMethod.regMethods({'type':'mobile','value':this.mobile})
            if(!resultMobile.status){
                Toast(resultMobile.msg)
                return;
            }
            let resultPassword = PublicMethod.regMethods({'type':'password','value':this.newPassword})
            // console.log(resultPassword);
            if(!resultPassword.status){
                Toast(resultPassword.msg)
                return;
            }
            if (this.newPassword != this.confirmPassword) {
                Toast("新密码和确认密码不同！");
                return false;
            }
            if (!this.code) {
                Toast("请输入验证码");
                return false;
            }
            var params = {
                UserName: this.mobile,
                password: this.newPassword,
                confirmPassword: this.confirmPassword,
                captcha: this.code,
                sessionId:this.sessionId,
            }
            httpService.changePassword(params)
            .then(res => {
                if (res.success) {
                    Toast("修改密码成功");
                    SmartStorage.set("currentRouter", 'login');
                    _this.$router.replace({ path: "/login" });
                } else {
                    if (res.msg) {
                        //请填写新密码;请确认密码
                        Toast("" + res.msg);
                    } else {
                        Toast("系统错误");
                    }
                }
            })
            // .catch(error => { });
        },
        // 返回按钮
        onClickLeft(){
            this.$router.back();
        }
    }
}
