import PublicMethod from "../plugins/public";
import { removeCookie } from 'tiny-cookie';
import { SmartStorage } from 'smart-core-util'
export default {
    data(){
        return{
            errorCodeText:'' || 'error_description：The requested scope is invalid, unknown, malformed, or exceeds that which the client is permitted to request.'
        }
    },
    mounted:function(){
        PublicMethod.closeLoading();
        // 获取errorCode码
        this.getErrorCode();
    },
    methods:{
        getErrorCode(){
            let url = window.location.search.substring(1);
            if(url){
                let test = url.split('&');
                let obj = {};
                test.forEach(item => {
                    let temp = item.split('=')
                    obj[temp[0]] = temp[1];
                })

                switch(obj.errorCode) {
                    case '401': 
                        this.errorCodeText = '权限问题，请返回重新登陆！';

                        this.$dialog.alert({
                            title: '确认信息',
                            message: '鉴权失败，请重新登录！',
                            confirmButtonText:'重新登录'
                        }).then(() => {
                            this.onClickLeft();
                        });
                        break;
                    case '500': 
                        this.errorCodeText = '登录异常，请联系项目组。ID:' + obj.msgId;
                        break;
                    default: 
                        break;
                        
                }
            }
        },
        // 返回
        onClickLeft(){
            removeCookie("token"); // 清除token从而登录
            removeCookie("token",{ domain: window.idomain }); // 清除token从而登录
            SmartStorage.set("currentRouter", 'login');
            this.$router.replace({ path: "/login" }); // 返回到login
        }
    }
}